import {useEffect, useCallback, useRef} from 'react';
import Utils from "../utils/Utils";

const useResize = (
    winningDraw: number[],
    cellRefs: React.MutableRefObject<{ [key: number]: HTMLElement | null }>,
    gameBoardRef: React.MutableRefObject<HTMLElement | null>
) => {
    const cellPositions = useRef<{ [key: number]: { targetX: number, targetY: number } }>({});

    const getCellPosition = useCallback((number: number) => {
        const cell = cellRefs.current[number];
        if (cell && gameBoardRef.current) {
            const rect = cell.getBoundingClientRect();
            const gameBoardRect = gameBoardRef.current.getBoundingClientRect();

            const viewportHeight = window.innerHeight;
            let marginOffset = 0;
            if (Utils.isDesktopLarge()) {
                marginOffset = 0.55 * viewportHeight;
            } else if (Utils.isTablet() || Utils.isDesktop()) {
                marginOffset = 0.44 * viewportHeight;
            }
            const topBarHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--topbar-height-mobile-game')) || 0;

            const position = {
                targetX: rect.left - gameBoardRect.left + rect.width / 2,
                targetY: rect.top - gameBoardRect.top + rect.height / 2 + topBarHeight + marginOffset,
            };
            if (cellPositions.current) {
                cellPositions.current[number] = position;
                return position;
            }
        }
        return {targetX: 0, targetY: 0};
    }, []);

    useEffect(() => {
        const handleResize = () => {
            winningDraw.forEach((number) => {
                getCellPosition(number);
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return getCellPosition;
};

export default useResize;
