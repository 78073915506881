import {useEffect, useState} from 'react';

const useMultiplierButtonAnimation = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const mobileAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        variants: {
            start: {
                opacity: 1,
                scale: 1,
                x: 33,
                y: -4,
                rotate: 0,
                transition: {
                    duration: 0.7
                }
            },
            mid: {
                rotate: 360,
                transition: {
                    duration: 0.8,
                    delay: 1
                }
            },
        },
        animate: ["start", "mid"],
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const tabletAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        variants: {
            start: {
                opacity: 1,
                scale: 1,
                x: 46,
                y: -4,
                rotate: 0,
                transition: {
                    duration: 0.7
                }
            },
            mid: {
                rotate: 360,
                transition: {
                    duration: 0.8,
                    delay: 1
                }
            },
        },
        animate: ["start", "mid"],
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const desktopAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        variants: {
            start: {
                opacity: 1,
                scale: 1,
                x: 47,
                y: -7,
                rotate: 0,
                transition: {
                    duration: 0.7
                }
            },
            mid: {
                rotate: 360,
                transition: {
                    duration: 0.8,
                    delay: 1
                }
            },
        },
        animate: ["start", "mid"],
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const getAnimation = () => {
        if (windowWidth <= 767) {
            return mobileAnimation;
        } else if (windowWidth <= 1200) {
            return tabletAnimation;
        } else {
            return desktopAnimation;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return getAnimation();
}

export default useMultiplierButtonAnimation;
