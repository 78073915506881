import {MouseEventHandler, ReactNode} from 'react';
import {motion} from 'framer-motion';
import clsx from 'clsx';
import styles from './Button.module.scss';

interface Props {
    children?: ReactNode;
    ref?: any;
    className?: string;
    onClick?: MouseEventHandler;
    isSecondary?: boolean;
    isTertiary?: boolean;
    isDisabled?: boolean;
    isMenuButton?: boolean;
    isActive?: boolean;
    isGolden?: boolean;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
}

function Button({
                    children,
                    ref,
                    className,
                    onClick,
                    isSecondary,
                    isTertiary,
                    isDisabled,
                    isMenuButton,
                    isActive,
                    isGolden,
                    onMouseEnter,
                    onMouseLeave,
                }: Props): JSX.Element {
    const isPrimary: boolean =
        !isSecondary &&
        !isMenuButton &&
        !isTertiary &&
        !isGolden;

    return (
        <motion.button
            ref={ref}
            type="button"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={!!isDisabled}
            onClick={onClick}
            className={clsx(className, styles.wrapper, {
                [styles.primary]: isPrimary,
                [styles.secondary]: isSecondary,
                [styles.tertiary]: isTertiary,
                [styles.menuButton]: isMenuButton,
                [styles.active]: isActive,
                [styles.disabled]: isDisabled,
                [styles.golden]: isGolden,
            })}
            initial={{opacity: 0, scale: 0.9}}
            animate={{opacity: 1, scale: 1}}
            exit={{opacity: 0, scale: 0.9}}
            transition={{duration: 0.3}}
        >
            {children && <div className={styles.childrenWrapper}>{children}</div>}
        </motion.button>
    );
}

export default Button;
