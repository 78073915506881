import React, {useState} from 'react';
import {motion} from 'framer-motion';
import PrizesTable from "./components/PrizesTable";
import arrowIcon from '../../assets/svg/prizes-arrow.svg'
import goldNumber from '../../assets/prizes/gold-number.png'
import styles from './PrizePlans.module.scss';
import {useTranslation} from "react-i18next";

const PrizePlans = () => {
    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(false);
    const toggleContainer = () => setIsOpen(!isOpen);

    const containerVariants = {
        hidden: {x: '-307px'},
        visible: {
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 20,
            },
        },
    };

    return (
        <>
            <motion.div
                key={'bookmark'}
                className={styles.bookmark}
                onClick={toggleContainer}
                initial={{x: -110}}
                animate={{
                    x: isOpen ? 170 : -100,
                    backgroundColor: isOpen ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.4)'
                }}
                transition={{type: 'spring', stiffness: 300, damping: 20}}
            >
                <span className={styles.bookmarkText}>{t('prizes.header')}</span>
                <motion.img
                    key={'bookmarkIcon'}
                    src={arrowIcon}
                    className={styles.bookmarkIcon}
                    initial={{rotate: 0}}
                    animate={{rotate: isOpen ? 180 : 0}}
                    transition={{duration: 0.3}}
                    alt={'arrow'}
                >
                </motion.img>
            </motion.div>

            <motion.div
                className={styles.container}
                initial="hidden"
                animate={isOpen ? 'visible' : 'hidden'}
                variants={containerVariants}
            >
                <h1 className={styles.header}>{t('prizes.header')}</h1>
                <hr className={styles.breakLine}/>
                <div className={styles.containerContent}>
                    <PrizesTable
                        tableHeaders={[
                            {tableHeader: t('prizes.hits')},
                            {tableHeader: t('prizes.kenoPrize')},
                            {
                                tableHeader: t('prizes.specialPrize'),
                                imgSrc: goldNumber,
                            },
                        ]}
                    />
                </div>
            </motion.div>
        </>
    );
};

export default PrizePlans;
