import {useEffect, useState} from 'react';

const useMultiplierButtonAnimation = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const mobileAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        animate: {
            opacity: 1,
            scale: 1,
            x: 31,
            y: -6,
            rotate: 10,
            transition: {
                duration: 0.7
            }
        },
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const tabletAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        animate: {
            opacity: 1,
            scale: 1,
            x: 48,
            y: -4,
            rotate: 10,
            transition: {
                duration: 0.7
            }
        },
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const desktopAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        animate: {
            opacity: 1,
            scale: 1,
            x: 50,
            y: -7,
            rotate: 10,
            transition: {
                duration: 0.7
            }
        },
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const getAnimation = () => {
        if (windowWidth <= 767) {
            return mobileAnimation;
        } else if (windowWidth <= 1200) {
            return tabletAnimation;
        } else {
            return desktopAnimation;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return getAnimation();
}

export default useMultiplierButtonAnimation;
