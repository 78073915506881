import React, {useContext} from 'react';
import Button from "../elements/Button/Button";
import {GameContext} from "../../contexts/GameContext";

const SkipDrawing = () => {
    const { setSpeedMultiplier, setShowSkipDrawing } = useContext(GameContext);

    const handleSkipDrawing = () => {
        setSpeedMultiplier(2);
        setShowSkipDrawing(false);
    }
    return (
        <div>
            <Button
                isSecondary
                onClick={handleSkipDrawing}
            >
                Skip Drawing
            </Button>
        </div>
    );
};

export default SkipDrawing;
