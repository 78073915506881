import React, {useContext, useEffect, useState} from "react";
import {GameContext} from "../../contexts/GameContext";
import Board from "../Board/Board";
import {ERROR, GAME, INFO, MULTIPLIER, ROUND_END, START, STATS, UNFINISHED} from "../../config/constants";
import styles from './Start.module.scss';
import ActionBoard from "../ActionBoard/ActionBoard";
import MultiplierWheel from "../Multiplier/Multiplier";
import Game from "../Game/Game";
import GameInfo from "../GameInfo/GameInfo";
import PrizePlans from "../PrizePlans/PrizePlans";
import Prizes from "../Prizes/Prizes";
import GameInfoTab from "../GameInfoTab/GameInfoTab";
import apiClient from "../../utils/apiClient";
import {AppContext} from "../../contexts/AppContext";
import {useQuery} from "react-query";

function KenoBoard() {
    const {
        setBoard,
        picked,
        setPicked,
        pickLimit,
        isGoldNumberUsed,
        setGoldNumber,
        goldNumber,
        setGameState,
        gameState,
        setIsGoldNumberUsed,
        unfinishedTicket,
        setUnfinishedTicket,
        setIsMultiplierUsed,
        setMultiplier,
        setCurrentTicket,
    } = useContext(GameContext);
    const {tokenData, setCurrentModal, setCurrentError, isReplay} = useContext(AppContext);
    const [width, setWidth] = useState(window.innerWidth);

    const handlePick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, val: number) => {
        e.preventDefault();
        let nPicked = [...picked];

        if (goldNumber === val) {
            setGoldNumber(0);
            setIsGoldNumberUsed(false);
            nPicked = nPicked.filter(n => n !== val);
        } else if (nPicked.includes(val)) {
            if (goldNumber === 0 && isGoldNumberUsed) {
                setGoldNumber(val);
            } else {
                nPicked = nPicked.filter(n => n !== val);
            }
        } else if (isGoldNumberUsed && goldNumber === 0 && !nPicked.includes(val)) {
            setGoldNumber(val);
            nPicked.push(val);
        } else if (nPicked.length < pickLimit && !nPicked.includes(val)) {
            nPicked.push(val);
        }
        setPicked(nPicked);
    };

    const {data: dataUnfinishedTickets}: any = useQuery(
        'unfinishedTickets',
        () =>
            apiClient.post('api/Game/GetUnfinishedTickets', {
                gameId: 219,
                channel: '',
                additionalData: '',
            }),
        {
            refetchOnWindowFocus: false,
            enabled: !!tokenData?.access_token && !isReplay,
            onError: (error: any) => {
                setCurrentError(error.data);
                setCurrentModal(ERROR);
            },
        }
    );

    useQuery(
        'ticketData',
        () =>
            apiClient.get(`${unfinishedTicket.tickets[0].url}`, {}),
        {
            refetchOnWindowFocus: false,
            enabled: unfinishedTicket !== null,
            onSuccess: (response: any) => {
                console.log(response);
                setUnfinishedTicket(response);
                setCurrentTicket(response);
                setCurrentModal(UNFINISHED);
                setPicked(response.chosenNumbers);
                if (response.specialNumber !== null) {
                    setIsGoldNumberUsed(true);
                    setGoldNumber(response.goldNumber);
                } else {
                    setIsGoldNumberUsed(false);
                }
                if (response.boughtMultiplier) {
                    setIsMultiplierUsed(true);
                    setMultiplier(response.multiplier);
                } else {
                    setIsMultiplierUsed(false);
                }
            },
            onError: (error: any) => {
                setCurrentError(error.data);
                setCurrentModal(ERROR);
            },
        },
    );

    useEffect(() => {
        let newBoard: Array<Array<number>> = [];
        let index = 1;

        for (let rowIndex = 0; rowIndex < 8; rowIndex++) {
            newBoard.push([]);
        }

        for (let row = 0; row < newBoard.length; row++) {
            for (let cellIndex = 1; cellIndex <= 10; cellIndex++) {
                newBoard[row].push(index);
                index++;
            }
        }
        setBoard(newBoard);
        setGameState(START);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (dataUnfinishedTickets && dataUnfinishedTickets.length > 0) {
            setUnfinishedTicket(dataUnfinishedTickets[0]);
        }
    }, [dataUnfinishedTickets]);

    return (
        <div className={styles.keno}>
            {gameState === START && (
                <PrizePlans/>
            )}
            {gameState === START && (
                <GameInfoTab/>
            )}
            {gameState === START && (
                <div className={styles.mainBoard}>
                    <Board handlePick={handlePick}/>
                    <ActionBoard/>
                </div>
            )}
            {gameState === START && width >= 1200 && (
                <GameInfo isOnStartPage={true}/>
            )}
            {gameState === MULTIPLIER && <MultiplierWheel/>}
            {(gameState === GAME || gameState === ROUND_END) && <Game/>}
            {gameState === INFO && <GameInfo isOnStartPage={false}/>}
            {gameState === STATS && <Prizes/>}
        </div>
    );
}

export default KenoBoard;
