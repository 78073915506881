import {useCallback, useEffect, useRef, useState} from 'react';
import { gsap } from 'gsap';
import Utils from '../utils/Utils';

interface AnimationProps {
    picked: number[];
    winningDraw: number[];
    number: number;
    ballRef: React.RefObject<HTMLDivElement>;
    isResultsShown: boolean;
    canChangeTotalRowWidth: boolean;
    setCanChangeTotalRowWidth: (value: boolean) => void;
}

const useAnimation = ({
                          picked,
                          winningDraw,
                          number,
                          ballRef,
                          isResultsShown,
                          canChangeTotalRowWidth,
                          setCanChangeTotalRowWidth,
                      }: AnimationProps) => {

    const baseXRefs = useRef([0, 0]);
    const canChangeTotalRowWidthRef = useRef(canChangeTotalRowWidth);

    const [boardWidth, setBoardWidth] = useState(() => {
        const board = document.getElementById('board');
        const boardRect = board?.getBoundingClientRect();
        return boardRect ? boardRect.width : 0;
    });
    const boardWidthRef = useRef(boardWidth);

    const [wc, setWc] = useState(boardWidth / 2);
    const isWinningBall = picked.includes(number) && winningDraw.includes(number);
    const winningBalls = winningDraw.filter(value => picked.includes(value));
    const winningBallsCount = winningBalls.length;
    const ballSize = Utils.isTablet() || Utils.isDesktopLarge() ? 46 : 32;
    const endAnimationBallSize = ballSize * 1.4;
    const spacing = endAnimationBallSize * 0.25;
    const maxRows = winningBallsCount <= 4 ? 1 : 2;
    const ballsPerRow = Math.ceil(winningBallsCount / maxRows);
    const baseY = window.innerHeight * 0.15;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const rowWidths = [
        Math.min(ballsPerRow, winningBallsCount) * (endAnimationBallSize + spacing) - spacing,
        (winningBallsCount - ballsPerRow > 0
            ? Math.min(winningBallsCount - ballsPerRow, ballsPerRow)
            : 0) *
        (endAnimationBallSize + spacing) -
        spacing,
    ];

    const animateWinningBall = (ball: HTMLDivElement, ballIndex: number) => {
        const gridRow = Math.floor(ballIndex / ballsPerRow);
        const gridColumn = ballIndex % ballsPerRow;

        if (
            gridRow === 1 &&
            winningBallsCount - ballsPerRow < ballsPerRow &&
            canChangeTotalRowWidthRef.current
        ) {
            canChangeTotalRowWidthRef.current = false;
            setCanChangeTotalRowWidth(false);

            const newRowWidth =
                (winningBallsCount - ballsPerRow) * (endAnimationBallSize + spacing) - spacing;
            baseXRefs.current[1] = wc - newRowWidth / 2;
        }

        const xPos = baseXRefs.current[gridRow] + gridColumn * (endAnimationBallSize + spacing);
        const yPos = baseY + gridRow * (endAnimationBallSize + spacing);

        gsap.to(ball, {
            background: 'linear-gradient(180deg, #BDF774 0%, #1C854D 100%)',
            x: `${xPos}px`,
            y: `${yPos}px`,
            scale: 1.3,
            duration: 1,
            ease: 'linear',
            zIndex: 200,
        });

        if (ball) ball.classList.add('winningBall');
    };

    const animateLosingBall = (ball: HTMLDivElement) => {
        gsap.to(ball, {
            display: 'none',
            opacity: 0,
            duration: 0.5,
            ease: 'linear',
        });
    };

    const allBounceComplete = () => {
        const ball = ballRef.current;
        if (!isResultsShown || !ball) return;

        if (isWinningBall) {
            const ballIndex = winningBalls.indexOf(number);
            animateWinningBall(ball, ballIndex);
        } else {
            animateLosingBall(ball);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setBoardWidth(() => {
                const board = document.getElementById('board');
                const boardRect = board?.getBoundingClientRect();
                return boardRect ? boardRect.width : 0;
            });
            boardWidthRef.current = boardWidth;
            console.log('boardWidth', boardWidthRef.current);
            setWc(boardWidthRef.current / 2);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setWc(boardWidth / 2);
        baseXRefs.current[0] = wc - rowWidths[0] / 2;
        if (rowWidths[1] > 0) {
            baseXRefs.current[1] = wc - rowWidths[1] / 2;
        }
    }, []);

    return { allBounceComplete };
};

export default useAnimation;
