import React, {FC, useRef} from "react";
import styles from "./Tooltip.module.scss";

type TooltipProps = {
    isVisible: boolean;
    children: React.ReactNode;
};

const Tooltip: FC<TooltipProps> = ({ children, isVisible }) => {
    const tooltipRef = useRef<HTMLDivElement>(null);

    if (!isVisible) {
        return null;
    }

    return (
        <div
            ref={tooltipRef}
            className={styles.tooltipContainer}>
            {children}
        </div>
    );
};

export default Tooltip;
