import * as PIXI from 'pixi.js';
import {Application, Container, DisplayObject, Sprite, Text} from 'pixi.js';
import { GlowFilter } from '@pixi/filter-glow';
import gsap from 'gsap';
// @ts-ignore
import Chance from 'chance';
import {PixiPlugin} from 'gsap/PixiPlugin';
import wheelBackground from '../../../assets/wheel/wheel-bg.png';
import wheelPin from '../../../assets/wheel/wheel-pin.png';
import centralPin from '../../../assets/wheel/wheel-central-pin.png';
import wheelArrow from '../../../assets/wheel/wheel-arrow.png';
import multiplier1 from '../../../assets/wheel/sectors/multiplier-1.png';
import multiplier2 from '../../../assets/wheel/sectors/multiplier-2.png';
import multiplier3 from '../../../assets/wheel/sectors/multiplier-3.png';
import multiplier4 from '../../../assets/wheel/sectors/multiplier-4.png';
import multiplier5 from '../../../assets/wheel/sectors/multiplier-5.png';
import multiplier10 from '../../../assets/wheel/sectors/multiplier-10.png';
import Utils from "../../../utils/Utils";
import {assignMultipliersToAngles} from "../../../utils/assignMultipliers";

const chance = new Chance();

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const angles = [18, 54, 90, 126, 162, 198, 234, 270, 306, 342];

const indexMap = {
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 4,
    7: 4,
    8: 5,
    9: 5,
    10: 5,
} as { [key: number]: number };

const multipliers = [
    multiplier1,
    multiplier2,
    multiplier3,
    multiplier4,
    multiplier5,
    multiplier10,
];

export interface PixiSetupOptions {
    htmlElement: HTMLDivElement;
    chosenMultiplier: number;
    onSpinEnd: () => void;
    multipliersArray: string[];
}

export default class WheelPixiApp {
    private app!: Application;
    private width!: number;
    private height!: number;
    private readonly htmlElement!: HTMLDivElement;
    private wheel!: Container;
    private background!: Sprite;
    private arrow!: Sprite;
    private centralPin!: Sprite;
    private assignedMultipliers!: { [p: number]: (number)[] };
    private multipliersWheel!: Container;
    private multipliers: Sprite[] = [];
    private multipliersArray: string[] = [];
    private pinTriggers: number[] = [0, 36, 72, 108, 144, 180, 216, 252, 288, 324];
    private chosenMultiplier!: number;
    private spinGsap!: gsap.core.Tween;
    private nailTimeline: gsap.core.Timeline | null = null;
    public onSpinEnd: () => void;

    constructor({htmlElement, chosenMultiplier, onSpinEnd, multipliersArray}: PixiSetupOptions) {
        this.width = htmlElement.clientWidth;
        this.height = htmlElement.clientHeight;
        this.htmlElement = htmlElement;
        this.chosenMultiplier = chosenMultiplier;
        this.multipliersArray = multipliersArray;
        this.onSpinEnd = onSpinEnd;

        this.init();
        this.subscribe();
    }

    subscribe() {
        this.onResize();
    }

    init() {
        this.app = new Application({
            width: this.width,
            height: this.height,
            antialias: true,
            autoDensity: true,
            resolution: window.devicePixelRatio,
            backgroundAlpha: 0,
            resizeTo: this.htmlElement,
        });
        this.htmlElement.appendChild(this.app.view as HTMLCanvasElement);
        this.assignMultipliers();
        this.createChildren();
        this.onResize();

        const exposeApp = true;
        if (exposeApp) {
            (globalThis as any).__PIXI_APP__ = this.app; // eslint-disable-line
        }
    }

    createChildren() {
        this.wheel = new Container();

        this.background = this.createSprite(wheelBackground, {
            anchor: 0.5,
        });

        this.multipliersWheel = new Container();
        this.multipliersWheel.position.set(2, -2);

        this.arrow = this.createSprite(wheelArrow, {
            position: {x: 0, y: -174},
        });
        this.arrow.anchor.set(0.5, 0);
        this.arrow.pivot.set(0.5, 0);
        this.arrow.scale.set(0.5);

        this.centralPin = this.createSprite(centralPin, {
            anchor: 0.5,
        });

        this.wheel.pivot.set(0.5, 0.5);
        this.wheel.addChild(
            this.background as DisplayObject,
            this.multipliersWheel as DisplayObject,
            this.arrow as DisplayObject,
            this.centralPin as DisplayObject,
        );

        Object.entries(this.assignedMultipliers).forEach(([key, angles]) => {
            angles.forEach((angle) => {
                this.createSector(Number(key.slice(0, -1)), angle);
            });
        });

        Object.entries(this.assignedMultipliers).forEach(([key, angles]) => {
            angles.forEach((angle) => {
                const pinPosition = {
                    x: Math.cos(angle * (Math.PI / 180)) * 135,
                    y: Math.sin(angle * (Math.PI / 180)) * 135,
                }
                const sectorPin = this.createSprite(wheelPin, {
                    anchor: 0.5,
                    position: {x: pinPosition.x, y: pinPosition.y},
                });
                sectorPin.scale.set(1.2);
                this.multipliersWheel.addChild(sectorPin as DisplayObject);
            });
        });

        multipliers.forEach((multiplier) => {
            const sprite = this.createSprite(multiplier, {
                anchor: 0.5,
            });
            sprite.visible = false;
            sprite.scale.set(1.2);
            sprite.position.set(0, -65);
            this.multipliers.push(sprite);
            this.wheel.addChild(sprite as DisplayObject);
        });

        this.app.stage.addChild(this.wheel as DisplayObject);
    }

    createSprite(texture: string, {
            anchor = 0,
            position = {x: 0, y: 0},
        }: {
            anchor?: number;
            position?: { x: number; y: number };
        } = {}
    ) {
        const sprite = Sprite.from(texture);
        sprite.anchor.set(anchor);
        sprite.position.set(position.x, position.y);

        return sprite;
    }

    createSector(key: number, angle: number) {
        console.log('angle', angle);
        const sector = new Container();
        sector.name = 'sector';
        sector.pivot.set(0.5, 1);

        const selectedIndex = indexMap[key] ?? 0;
        const multiplierBg = multipliers[selectedIndex];
        const sectorBackground = this.createSprite(multiplierBg);
        sectorBackground.anchor.set(0.5, 1);
        sectorBackground.name = 'background';
        sectorBackground.scale.set(0.73, 0.73);
        sectorBackground.position.set(0, 2);
        sector.addChild(sectorBackground as DisplayObject);

        const sectorText = new Text(`x${key}`, {
            fontFamily: 'Kanit',
            fontSize: 34,
            fontWeight: 'bold',
            fill: 0xffffff,
            align: 'center',
        });
        sectorText.anchor.set(0.5);
        sectorText.position.set(2, -110);
        sector.addChild(sectorText as DisplayObject);

        sector.rotation = -(angle * (Math.PI / 180));

        // sector.addChild(sectorPin as DisplayObject);
        this.multipliersWheel.addChild(sector as DisplayObject);
    }

    assignMultipliers() {
        const possibleAngles = [...angles];
        // @ts-ignore
        this.assignedMultipliers = assignMultipliersToAngles(this.multipliersArray, possibleAngles);
        console.log('this.assignedMultipliers', this.assignedMultipliers);
    }

    spin() {
        console.log('this.chosenMultiplier', this.chosenMultiplier);
        // @ts-ignore
        const stopAngles = this.assignedMultipliers[`${this.chosenMultiplier}x`];
        console.log('stopAngles', stopAngles);
        const targetAngle = chance.pickone(stopAngles) * (Math.PI / 180);

        const totalRotation = (Math.PI * 2) * 7 + targetAngle;
        let lastLoggedRotation = -1;

        this.spinGsap = gsap.to(this.multipliersWheel, {
            rotation: totalRotation,
            duration: 8,
            ease: "circ.out",
            onUpdate: () => {
                const totalRotationDegrees = totalRotation * (180 / Math.PI);
                const currentRotation = (this.multipliersWheel.rotation * (180 / Math.PI)) % 360;

                const nearestPin = this.pinTriggers.find(pin => Math.abs(pin - currentRotation) < 5);
                if (nearestPin !== undefined && lastLoggedRotation !== nearestPin) {
                    const spinProgressInDegrees = gsap.utils.mapRange(0, 1, 0, totalRotationDegrees, this.spinGsap.progress());
                    this.playPointerAnimation(spinProgressInDegrees);
                    lastLoggedRotation = nearestPin;
                }
            },
            onComplete: () => {
                this.onSpinEnd();
                this.onMultiplierSelected(this.chosenMultiplier);
            }
        });
    }

    playPointerAnimation(spinProgress: number) {
        if (this.nailTimeline) {
            this.nailTimeline.kill();
        }

        const maxRotation = 0.5;
        const distanceToPin = Math.abs(0.5 - spinProgress);
        const snapIntensity = gsap.utils.clamp(0.5, 1, distanceToPin * 2);

        this.nailTimeline = gsap.timeline();

        this.nailTimeline.to(this.arrow, {
            rotation: -maxRotation * snapIntensity,
            duration: snapIntensity * 0.05,
            ease: 'elastic.out(1, 0.3)',
        });

        this.nailTimeline.to(this.arrow, {
            rotation: 0,
            duration: 0.2,
            ease: 'power2.out',
        });

        this.nailTimeline.play();
    }

    onMultiplierSelected(chosenMultiplier: number) {
        const selectedIndex = indexMap[chosenMultiplier];
        const selectedSprite = this.multipliers[selectedIndex];

        selectedSprite.position.y += 20;

        gsap.timeline()
            .to([this.multipliersWheel, this.arrow, this.centralPin], {
                alpha: 0,
                y: '-=100',
                duration: 0.7,
                ease: 'power1.inOut',
                onComplete: () => {
                    this.multipliersWheel.visible = false;
                    this.arrow.visible = false;
                }
            })
            .to(this.background, {
                alpha: 0,
                y: '+=100',
                skewX: 0.5,
                duration: 0.5,
                ease: 'power1.out'
            }, '-=0.5');

        selectedSprite.visible = true;

        const glowFilter = new GlowFilter({
            distance: 35,
            outerStrength: 2,
            innerStrength: 0,
            alpha: 0.7,
        });

        selectedSprite.filters = [glowFilter];

        gsap.timeline()
            .to(glowFilter, {
                distance: 75,
                outerStrength: 3,
                duration: 1.5,
                repeat: -1,
                yoyo: true,
                ease: 'power1.inOut'
            });

        const multiplierText = new PIXI.Text(`x${chosenMultiplier}`, {
            fontFamily: 'Kanit',
            fontSize: 32,
            fontWeight: 'bold',
            fill: 0xffffff,
            align: 'center'
        });
        multiplierText.anchor.set(0.5);
        multiplierText.position.set(0, -30);
        selectedSprite.addChild(multiplierText as DisplayObject);

        gsap.timeline()
            .to(selectedSprite.scale, {
                x: 0.85,
                y: 0.85,
                duration: 0.4,
                ease: 'power4.inOut'
            });
    }

    onResize() {
        this.width = this.wheel.width;
        this.height = this.wheel.height;
        if (this.wheel && this.wheel.transform && this.background && this.background.transform) {
            if (Utils.isSmallMobile()) {
                this.wheel.scale.set(0.75);
                this.wheel.position.set(160, 150);
            } else if (Utils.isMobile()) {
                this.wheel.scale.set(0.85);
                this.wheel.position.set(160, 160);
            } else if (Utils.isTablet()) {
                this.wheel.scale.set(1);
                this.wheel.position.set(260, 210);
            } else if (Utils.isDesktop()) {
                this.wheel.scale.set(1.1);
                this.wheel.position.set(260, 215);
            }
        }
    }

    destroy() {
        this.app.destroy(true, {children: true});
    }
}
