import React, {useContext, useEffect, useRef} from 'react';
import gsap from 'gsap';
import styles from './Ball.module.scss'
import {GameContext} from "../../../contexts/GameContext";
import Utils from "../../../utils/Utils";
import useAnimation from "../../../hooks/useAllBounceCompleteAnimation";
import {debounce} from "lodash";

interface BallProps {
    targetX: number;
    targetY: number;
    unbouncedBallsRef: React.MutableRefObject<HTMLDivElement[]>;
    delay: number;
    number: number;
    onBounceComplete: () => void;
    hasBounced?: boolean;
}

const Ball = ({targetX, targetY, unbouncedBallsRef, number, delay, onBounceComplete, hasBounced}: BallProps) => {
    const ballRef = useRef<HTMLDivElement | null>(null);
    const timelineRef = useRef<gsap.core.Timeline | null>(null);
    const {
        isResultsShown,
        picked,
        winningDraw,
        speedMultiplier,
        canChangeTotalRowWidth,
        setCanChangeTotalRowWidth
    } = useContext(GameContext);

    const isWinningBall = picked.includes(number) && winningDraw.includes(number);
    const ballSize = Utils.isTablet() || Utils.isDesktop() ? 39 : Utils.isDesktopLarge() ? 60 : 32;

    const {allBounceComplete} = useAnimation({
        picked,
        winningDraw,
        number,
        ballRef,
        isResultsShown,
        canChangeTotalRowWidth,
        setCanChangeTotalRowWidth,
    });

    useEffect(() => {
        const ball = ballRef.current;
        const board = document.getElementById('board');
        if (!ball || !board) return;
        const boardRect = board?.getBoundingClientRect();
        let boardCenterX;
        if (Utils.isTablet() || Utils.isDesktop()) {
            boardCenterX = boardRect ? boardRect.width / 2 - 20 : 0;
        } else {
            boardCenterX = boardRect ? boardRect.width / 2 - 15 : 0;
        }

        const adjustedTargetX = targetX - (ballSize / 2);
        const adjustedTargetY = targetY - (ballSize / 2);

        const timeline = gsap.timeline({
            onComplete: () => onBounceComplete(),
        });
        timeline.fromTo(ball,
            {y: '-100px', x: boardCenterX, opacity: 1, width: `${ballSize}px`, height: `${ballSize}px`, scale: 1.3},
            {y: window.innerHeight - ballSize - 15, opacity: 1, duration: 2.8, ease: 'power2.in', yoyo: true, delay}
        )
            .to(ball, {
                y: adjustedTargetY,
                x: adjustedTargetX,
                duration: 1.1,
                opacity: 0.8,
                scale: 1,
                ease: 'linear',
                onComplete: () => onBounceComplete(),
            });

        timelineRef.current = timeline;

        return () => {
            timeline.kill();
        };
    }, []);

    useEffect(() => {
        const handleResize = debounce(() => {
            const ball = ballRef.current;
            if (!ball || isResultsShown) return;
            const ballRect = ball.getBoundingClientRect();
            const ballPositionY = ballRect.top + ballRect.height / 2;
            const newBallSize = Utils.isTablet() || Utils.isDesktop() ? 39 : Utils.isDesktopLarge() ? 60 : 32;
            const adjustedTargetX = targetX - (newBallSize / 2);
            const adjustedTargetY = targetY - (newBallSize / 2);
            if (ballPositionY > 0) {
                gsap.to(ball, {
                    x: adjustedTargetX,
                    y: adjustedTargetY,
                    width: newBallSize,
                    height: newBallSize,
                    duration: 0.5,
                });
                return;
            }
            if (!unbouncedBallsRef.current.includes(ball)) {
                unbouncedBallsRef.current.push(ball);
            }

            if (timelineRef.current) {
                timelineRef.current.kill();
            }

            const timeline = gsap.timeline({
                onComplete: () => onBounceComplete(),
            });
            timelineRef.current = timeline;

            const board = document.getElementById('board');
            const boardRect = board?.getBoundingClientRect();
            const boardCenterX = boardRect ? boardRect.width / 2 - (Utils.isTablet() || Utils.isDesktop() ? 20 : 15) : 0;

            const newDelay = unbouncedBallsRef.current.indexOf(ball) * 1.4;

            timeline.fromTo(ball,
                {y: '-100px', x: boardCenterX, opacity: 1, width: `${ballSize}px`, height: `${ballSize}px`, scale: 1.3},
                {
                    y: window.innerHeight - newBallSize - 15,
                    opacity: 1,
                    duration: 2.8,
                    ease: 'power2.in',
                    yoyo: true,
                    delay: newDelay,
                }
            ).to(ball, {
                x: adjustedTargetX,
                y: adjustedTargetY,
                width: newBallSize,
                height: newBallSize,
                opacity: 0.8,
                scale: 1,
                duration: 1.1,
                ease: 'linear',
                onComplete: () => onBounceComplete(),
            });
        }, 20);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [targetX, targetY]);

    useEffect(() => {
        if (isResultsShown) {
            allBounceComplete();
        }
    }, [isResultsShown]);

    useEffect(() => {
        if (timelineRef.current) {
            timelineRef.current.timeScale(speedMultiplier);
        }
    }, [speedMultiplier]);

    return (
        <div
            ref={ballRef}
            className={styles.ball}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'black',
                fontFamily: 'Kanit',
                fontSize: '16px',
                fontWeight: 'bold',
            }}
        >
            {isResultsShown && isWinningBall && number}
            <div className={styles.glare}></div>
            <div className={styles.secondary}></div>
            <div className={styles.inner}></div>
        </div>

    );
};

export default Ball;
