import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import styles from './GameInfo.module.scss'
import clsx from "clsx";
import React, {useContext} from "react";
import Button from "../elements/Button/Button";
import {GameContext} from "../../contexts/GameContext";
import {START, STATS} from "../../config/constants";

interface GameInfoProps {
    isOnStartPage: boolean;
}

const GameInfo = ({isOnStartPage}: GameInfoProps) => {
    const {t} = useTranslation();
    const {setGameState} = useContext(GameContext);

    return (
        <>
            {isOnStartPage ? (
                <motion.div
                    className={clsx(styles.infoPage, {
                        [styles.startPage]: isOnStartPage && window.innerWidth > 1200,
                    })}
                    id='info'
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <div className={clsx(styles.tutorialBox, {
                        [styles.startPage]: isOnStartPage,
                    })}>
                        {isOnStartPage && window.innerWidth > 1200 && (
                            <>
                                <h1 className={styles.header}>{t('info.header')}</h1>
                                <hr className={styles.breakLine}/>
                            </>
                        )}
                        <div className={styles.firstSection}>
                            <p className={styles.firstParagraph}>{t('info.firstParagraph')}</p>
                        </div>

                        <div className={styles.secondSection}>
                            <p className={styles.secondParagraph}>{t('info.secondParagraph')}</p>
                        </div>

                        <div className={styles.thirdSection}>
                            <p className={styles.thirdParagraph}>{t('info.thirdParagraph')}</p>
                        </div>

                        <div className={styles.forthSection}>
                            <p className={styles.forthParagraph}>{t('info.forthParagraph')}</p>
                        </div>

                    </div>
                </motion.div>
            ) : (
                <motion.div
                    className={styles.infoPage}
                    id='info'
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <div className={styles.tutorialBox}>
                        <div className={styles.firstSection}>
                            <p className={styles.firstParagraph}>{t('info.firstParagraph')}</p>
                        </div>

                        <div className={styles.secondSection}>
                            <p className={styles.secondParagraph}>{t('info.secondParagraph')}</p>
                        </div>

                        <div className={styles.thirdSection}>
                            <p className={styles.thirdParagraph}>{t('info.thirdParagraph')}</p>
                        </div>
                    </div>
                    <div className={styles.bottomBlock}>
                        <Button
                            className={styles.statsButton}
                            isSecondary
                            onClick={() => setGameState(STATS)}>
                            {t('info.buttonOne')}
                        </Button>
                        <Button
                            className={styles.startButton}
                            onClick={() => setGameState(START)}>
                            {t('info.buttonTwo')}
                        </Button>
                    </div>
                </motion.div>
            )}
        </>
    );
}

export default GameInfo;
