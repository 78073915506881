import React, {useContext, useEffect, useRef, useState} from 'react';
import throttle from 'lodash.throttle';
// @ts-ignore
import Chance from 'chance';
import {GameContext} from "../../contexts/GameContext";
import WheelPixiApp from './PixiApp/WheelPixiApp';
import Button from '../elements/Button/Button';
import clsx from "clsx";
import gsap from "gsap";
import {GAME} from "../../config/constants";
import styles from './Multiplier.module.scss';
import {AppContext} from "../../contexts/AppContext";
import Utils from "../../utils/Utils";

const chance = new Chance();

const MultiplierWheel = () => {
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const applicationRef = useRef<WheelPixiApp | null>(null);
    const {tokenData} = useContext(AppContext);
    const {setGameState, currentTicket, multipliersArray} = useContext(GameContext);
    const [multiplier, setMultiplier] = useState<number | null>(null);
    const [isSpinning, setIsSpinning] = useState(false);

    const possibleMultipliers = () => {
        return multipliersArray.map((multiplier) => Utils.formatMultiplier(multiplier));
    };

    const onResize = throttle(() => {
        if (applicationRef.current) {
            applicationRef.current.onResize();
        }
    }, 100);

    const handleSpin = () => {
        setIsSpinning(true);
        if (applicationRef.current) {
            applicationRef.current.spin();
        }
    };

    const handleSkip = () => {
        setMultiplier(tokenData ? currentTicket.multiplier : chance.pickone(multipliersArray));
        setGameState(GAME);
    };

    const handleSpinEnd = () => {
        setIsSpinning(false);
        setMultiplier(tokenData ? currentTicket.multiplier : chance.pickone(multipliersArray));
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            if (applicationRef.current) {
                window.removeEventListener('resize', onResize);
                applicationRef.current.destroy();
                applicationRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (applicationRef.current) {
            applicationRef.current.onResize();
        }
    }, []);

    useEffect(() => {
        if (!applicationRef.current && canvasRef.current) {
            applicationRef.current = new WheelPixiApp({
                htmlElement: canvasRef.current,
                chosenMultiplier: currentTicket.multiplier || 1,
                onSpinEnd: handleSpinEnd,
                multipliersArray: possibleMultipliers(),
            });
        }

        gsap.fromTo(
            canvasRef.current,
            { opacity: 0, y: -50 },
            { opacity: 1, y: 0, duration: 1, ease: 'bounce.out' }
        );
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.canvasBox} ref={canvasRef}/>
            <div className={clsx(styles.resultBox)}>
                <div className={clsx(styles.textBox, {
                    [styles.fade]: multiplier !== null,
                    [styles.visible]: multiplier !== null,
                    [styles.hidden]: multiplier === null
                })}>
                    <h1 className={styles.result}>Multiplier: {` x ${multiplier}`}</h1>
                    <span className={styles.resultText}>You have a chance of winning {multiplier} times more!</span>
                </div>
                <Button onClick={handleSpin}
                        className={clsx(styles.spinButton, {
                            [styles.fade]: multiplier !== null,
                            [styles.visible]: multiplier === null,
                            [styles.hidden]: multiplier !== null,
                        })}>
                    Spin wheel
                </Button>
            </div>
            {!isSpinning && (
                <Button isSecondary onClick={handleSkip} className={styles.skipButton}>
                    Skip!
                </Button>
            )}
        </div>
    );
};

export default MultiplierWheel;
