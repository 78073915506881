import React, {useContext, useEffect, useState} from 'react';
import Button from "../../../elements/Button/Button";
import clsx from "clsx";
import Utils from "../../../../utils/Utils";
import {AnimatePresence, motion} from "framer-motion";
import styles from './PriceMenu.module.scss';
import {GameContext} from "../../../../contexts/GameContext";
import {useTranslation} from "react-i18next";
import {ROUND_END} from "../../../../config/constants";

interface PriceMenuProps {
    activePrice: number;
    setActivePrice: (price: number) => void;
}

const PriceMenu: React.FC<PriceMenuProps> = ({activePrice, setActivePrice}) => {
    const {t} = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {prices, prizePlans, picked, isGoldNumberUsed, gameState} = useContext(GameContext);
    const [topPrizeValue, setTopPrizeValue] = useState<number>(0);

    const priceList = [...prices].reverse();

    const handlePriceClick = (price: number) => {
        setActivePrice(price);
        setIsMenuOpen(false);
    };

    const handleOpenMenu = () => {
        if (gameState === ROUND_END) return;
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        let plan = prizePlans.find(plan =>
            plan.chosenNumbersCount === picked.length &&
            !plan.isSpecialPrize,
        );
        if (!plan) {
            plan = prizePlans.reduce((maxPlan, currentPlan) =>
                currentPlan.chosenNumbersCount > (maxPlan?.chosenNumbersCount || 0) ? currentPlan : maxPlan, null);
        }
        if (plan) {
            const maxPrize = Math.max(...plan.prizes.map((prize: { prizeFactor: any; }) => prize.prizeFactor));
            setTopPrizeValue(maxPrize);
        }
    }, [prizePlans, picked.length, isGoldNumberUsed]);

    return (
        <AnimatePresence key='priceMenu'>
            <Button
                key='activePriceButton'
                className={styles.activePriceButton}
                onClick={handleOpenMenu}
            >
                {Utils.formatCurrency(activePrice)}
                <p className={styles.topPrizeLabel}>{t('actionBoard.topPrize')}</p>
                <p className={styles.topPrizeValue}>{Utils.formatCurrency(topPrizeValue * activePrice)}</p>
            </Button>
            <motion.div
                className={styles.priceList}
                animate={{
                    height: isMenuOpen ? '' : '70px',
                    background: isMenuOpen ? 'linear-gradient(180deg, #0F1940 72%, #28335F 100%)' : 'rgba(0,0,0,0)',
                    transition: {
                        delayChildren: 0.3,
                        staggerChildren: 0.2
                    }
                }}
            >
                {isMenuOpen && (
                    <>
                        {priceList.map((price, index) => (
                            <Button
                                key={`${price}-${index}`}
                                className={clsx(styles.priceButton, {
                                    [styles.inactive]: price !== activePrice,
                                })}
                                onClick={() => handlePriceClick(price)}
                            >
                                {Utils.formatCurrency(price)}
                                <p className={styles.topPrizeLabel}>{t('actionBoard.topPrize')}</p>
                                <p className={styles.topPrizeValue}>{Utils.formatCurrency(topPrizeValue * activePrice)}</p>
                            </Button>
                        ))}
                    </>
                )}
            </motion.div>
        </AnimatePresence>
    );
};

export default PriceMenu;
