function assignMultipliersToAngles(multipliers: any[], angles: number[]) {
    multipliers.sort((a, b) => parseFloat(a) - parseFloat(b));

    const highestMultiplier = multipliers.pop();
    const result = { [highestMultiplier]: [angles.pop()] };

    const counts = {};
    let totalAngles = angles.length;

    if (multipliers.includes(1)) {
        // @ts-ignore
        counts['1x'] = 3;
        totalAngles -= 3;
    }

    multipliers.forEach((multiplier, index) => {
        let count;
        if (multiplier === '1x') {
            count = 3;
        } else {
            count = Math.ceil(totalAngles / (multipliers.length - index))
        }
        console.log(multiplier, index);
        // @ts-ignore
        counts[multiplier] = count;
        totalAngles -= count;
    });

    const usedAngles = new Set();
    multipliers.forEach((multiplier) => {
        result[multiplier] = [];

        // @ts-ignore
        for (let i = 0; i < counts[multiplier]; i++) {
            let validAngles = angles.filter(
                (angle) =>
                    !usedAngles.has(angle) &&
                    !result[multiplier].includes(getPreviousAngle(angle, angles)) &&
                    !result[multiplier].includes(getNextAngle(angle, angles))
            );

            if (validAngles.length === 0) {
                validAngles = angles.filter((angle) => !usedAngles.has(angle));
            }

            const selectedAngle = validAngles[Math.floor(Math.random() * validAngles.length)];
            result[multiplier].push(selectedAngle);
            usedAngles.add(selectedAngle);
        }
    });

    return result;
}

function getPreviousAngle(angle: number, angles: number[]) {
    const index = angles.indexOf(angle);
    return angles[(index - 1 + angles.length) % angles.length];
}

function getNextAngle(angle: number, angles: number[]) {
    const index = angles.indexOf(angle);
    return angles[(index + 1) % angles.length];
}

export {assignMultipliersToAngles};

const angles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
const multipliers = ["1х", "2х", "3х", "10х"];
