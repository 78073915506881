import { useEffect } from "react";
import { gsap } from "gsap";

const useCloudAnimation = () => {
    useEffect(() => {
        const animateClouds = () => {
            const clouds = gsap.utils.toArray(".cloud") as HTMLElement[];

            clouds.forEach((cloud, index) => {
                gsap.killTweensOf(cloud);
                const cloudWidth = cloud.offsetWidth;
                const screenWidth = window.innerWidth;

                gsap.fromTo(
                    cloud,
                    {
                        x: screenWidth + cloudWidth + gsap.utils.random(0, 100),
                        y: gsap.utils.random(5, 80),
                        scale: gsap.utils.random(0.7, 1.2),
                    },
                    {
                        x: -screenWidth - cloudWidth,
                        duration: gsap.utils.random(40, 60),
                        ease: "none",
                        repeat: -1,
                        delay: index * 15,
                        repeatDelay: gsap.utils.random(5, 10),
                    }
                );
            });
        };

        animateClouds();

        const handleResize = () => animateClouds();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            gsap.killTweensOf(".cloud");
        };
    }, []);
};

export default useCloudAnimation;
